import { resizeEditorSlice } from "./resizeEditorSlice";

export const {
    // image
    setImage,
    imageSetRatio,
    // resize
    resizeSetColor,
    resizeSetDPI,
    resizeSetDimensionType,
    resizeSetEditType,
    resizeSetHeight,
    resizeSetIsLocked,
    resizeSetPercentage,
    resizeSetSaveAs,
    resizeSetShouldFillColor,
    resizeSetTargetFileSize,
    resizeSetUnit,
    resizeSetWidth,
    resizeToggleDimension,
    resizeSetOptimizationLevel,
    resizeSetFileUnit,
    resizeSetFillColorType,
    // crop
    cropSetHeight,
    cropSetWidth,
    cropSetRatio,
    cropToggleCropOrientation,
    cropSetLeft,
    cropSetTop,
    cropSetCropBox,
    cropReset,
    cropHandleCropBoxChange,
    cropSetCustomRatioX,
    cropSetCustomRatioY,
    // flip
    flipToggleHorizontal,
    flipToggleVertical,
    flipReset,
    // rotate
    rotateSetStraighten,
    rotateSetRotation,
    rotateReset,
    rotateSetInitialData,
    // history
    historyPop,
    historyPush,
    // modal
    modalClose,
    modalOpen,
    // utils
    reset,
    resetSettings,
    // operaions
    addOperation,
    deleteAllOperations,
} = resizeEditorSlice.actions;
